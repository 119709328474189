//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SiteCard from 'components/site-selector/site-card.vue'
import FooterIndex from 'pages/footer-index.vue'

export default {
  name: 'page-index',
  components: {
    SiteCard,
    FooterIndex
  },
  data() {
    return {
      logo: process.env.LOGO,
      slide: 'AUTOS',
      sites: [
        {
          title: 'AUTOS',
          subtitle: 'Maneja, vive, disfruta',
          background: require('assets/bg-autos.png'),
          link: 'https://autos.suzuki.com.mx',
          image: require('assets/site-autos.png'),
          index: 2,
          clip: 'right'
        },
        {
          title: 'MOTOS',
          subtitle: 'Velocidad, seguridad y fuerza',
          background: require('assets/bg-motos.jpg'),
          link: 'https://motos.suzuki.com.mx',
          image: require('assets/site-motos.png'),
          index: 0,
          clip: 'none'
        },
        {
          title: 'MOTORES<br>MARINOS',
          subtitle: 'Poder, estilo y potencia',
          background: require('assets/bg-motores.jpg'),
          link: 'https://motoresmarinos.suzuki.com.mx',
          image: require('assets/site-motores.png'),
          index: 1,
          clip: 'left'
        }
      ]
    }
  }
}
